/* TIMELINE */

.applyHeader {
  margin: 50px var(--margin-from-screen);
}

.headerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blueCurve {
  padding: 2% 0%;
  width: 100%;
}

.applyHeader h1 {
  font-size: 40px;
  margin: 0;
  font-family: 'Brother Bold';
}

.applyHeader p {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  width: 70%;
}

.timeline {
	padding-bottom: 2%;
  margin-left: var(--margin-from-screen);
  margin-right: var(--margin-from-screen);
}

.timelineWidth {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelineWidth h1 {
  padding-bottom: 20px;
}

.timeline a {
	margin: auto;
}

.timeline h1 {
  color: #2D5DED;
  padding: 0;
}

.blue {
  color: #2D5DED;
}

.outer {
  border-left: 7px solid #2D5DED;
}

.card {
  position: relative;
  margin: 0 0 0 20px;
  padding: 10px;
  color: #3A3A3A;
  border-radius: 8px;
}

.info {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.title {
  position: relative;
  font-size: 50px;
  font-family: 'Brother Medium Italic';
  margin: 0;
  padding: 0;
}

.title::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: white;
  border-radius: 50%;
  left: -43px;
  top: 30px;
  border: 5px solid #2D5DED;
}

.content {
  margin-top: 15px;
}

.date {
  color: #FF9C03;
  margin: 0;
}

.subtitle {
  font-family: 'Brother Bold';
  margin: 0;
}

.information {
  margin: 0;
}

.stackContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.timelineContent {
  padding: 20px 0;
}

.importantNote {
  font-family: 'Brother Bold';
  border: 3px solid #FF9C03;
  padding: 10px;
  background-color: #FF9C03;
  color: white;
}

@media screen and (max-width: 1000px) {
  .applyHeader p {
    font-size: 15px;
  }
}

@media screen and (max-width: 550px) {
  .applyButton {
    font-size: 12px;
  }

  .timeline {
    padding-bottom: 30px;
  }
}

@media screen and (max-width: 400px) {
  .headerBlock {
    flex-direction: column;
    align-items: center;
  }
  .applyHeader h1 {
    text-align: center;
  }
  .headerBlock p {
    width: 100%;
    text-align: center;
  }
  .applyButton {
    font-size: 15px;
  }
}

/* FAQ */

.faqBox {
    background: #FFE9C7;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 20px;
    cursor: pointer;
    transition: 0.2s;
}

.faqBox:hover {
    filter: brightness(95%);
    transition: 0.2s;
}

.faqHeader {
    width: 100%;
    display: grid;
    grid-template-columns: 90fr 10fr;
}

.faqImageContainer {
    position: relative;
}

.faqQuestion {
    font-weight: bold;
    font-size: x-large;
}

@media screen and (max-width: 870px) {
    .faqQuestion {
        font-weight: bold;
        font-size: large;
    }
}

.faqAnswer, .faqAnswerInvisible {
    white-space: pre-wrap;
    overflow: hidden;
    max-height: 500px;
    transition: 0.3s;
}

.faqAnswerInvisible {
    margin: 0;
    max-height: 0;
}

.faqPlus, .faqPlusRotate {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    transition: transform 0.3s;
}

.faqPlusRotate {
    transform: rotate(-45deg);
}

.faqAnswerContainer {
    width: 100%;
}

.faqTitle {
    color: #FF9C03;
    font-size: 40px;
    margin-top: 0;
}

.allFaqs {
    padding: 10px var(--margin-from-screen);
}

.orangeCurve {
    max-height: 400px;
    width: 100%;
}

.faqQuestion {
  margin: 0;
  font-family: 'Brother Bold';
}