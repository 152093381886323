.cover {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 50px var(--margin-from-screen);
    justify-content: space-between;
}

.coverLeft {
    max-width: 500px;
}

.coverLeft h1 {
    font-family: 'Brother Bold';
    margin: -20px 0;
    font-size: 50px;
}

.coverRight {
    max-width: 500px;
    animation: shake 2s infinite;
}

.coverRight img {
    width: 100%;
}

@keyframes shake{
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(10deg);
    }
    100% {
      transform: rotate(0deg);
    }
}

.getInvolvedButton {
    background-color: #FF9C03;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-family: 'Brother Bold';
    font-size: 20px;
    cursor: pointer;
}

.bluesection {
    display: flex;
    flex-direction: column;
}

.bluesection img {
    width: 100%;
}

.bluebackground {
    background-color: #2D5DED;
}

.blue {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px var(--margin-from-screen);
    gap: 20px;
}

.blue img {
    max-width: 400px;
}

.bluetext {
    max-width: 600px;
}

.bluetext h1 {
    font-family: 'Brother Bold';
    color: #F9C12C;
}

.bluetext h3 {
    color: white;
}

.blueText {
    color:#2D5DED;
}

.about {
    display: flex;
    flex-direction: row;
    padding: 50px var(--margin-from-screen);
    justify-content: space-between;
    gap: 50px;
    align-items: center;    
}

.about h1 {
    font-family: 'Brother Bold';
}

.aboutCommunity h1 {
    color:#FF9C03;
}

.aboutProjects h1 {
    color:#2D5DED;
}

.about h3 {
    max-width: 500px;
}

.about img {
    width: 100%;
    max-width: 400px;
}

.about img:hover {
    animation: shakeRight 2s infinite;
}

.orangeText {
    color:#FF9C03;
}

.joinUs {
    display: flex;
    margin: 10px var(--margin-from-screen);
    gap: 50px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 100px;
    margin-top: -10px;
}


.joinUsText {
    display: flex;
    flex-direction: column;
}

.joinUsText h1 {
    display: flex;
    font-family: 'Brother Bold';
    font-size: 60px;
}

.joinUsText h3 {
    display: flex;
    font-size: 20px;
    max-width: 600px;
    margin-top: -20px;
}

.joinUsRight {
    margin-bottom: 20px;
}

.getInvolvedButton {
    min-width: 200px;
    font-size: 25px;
}

.orangeCurve {
    width: 100%;
}

@media screen and (max-width: 870px) {
    .cover {
        flex-direction: column;
        gap: 50px;
    }

    .blue {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    }

    .aboutCommunity {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .aboutProjects {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 50px;
    }

    .about h3 {
        max-width: 900px;
    }

    .joinUs {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .joinUsText {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
}

@keyframes shakeRight{
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(10deg);
    }
}

@keyframes shakeLeft {
    0% {
      transform: rotate(0deg);
    }
    20% {
      transform: rotate(-10deg);
    }
    100% {
      transform: rotate(0deg);
    }
}