html, body {
  margin: 0;
  font-family: 'Brother Medium';
  background-color: #FFFDF8;
  letter-spacing: 0;
}

.limitWidth {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

:root {
  --margin-from-screen: 30px;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('./fonts/brother\ regular.otf');
}

@font-face {
  font-family: 'Brother Medium';
  src: url('./fonts/brother\ medium.otf');
}

@font-face {
  font-family: 'Brother Medium Italic';
  src: url('./fonts/brother\ medium\ italic.otf');
}

@font-face {
  font-family: 'Brother Light Italic';
  src: url('./fonts/brother\ light\ italic.otf');
}

@font-face {
  font-family: 'Brother Bold';
  src: url('./fonts/brother\ bold.otf');
}

pre {
  background-color: #f8f8f8;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.5;
  overflow: auto;
  padding: 16px;
}

code {
  font-family: Consolas, monospace;
}